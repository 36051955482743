import React from "react";

export default function IconModalGiftCode({width, height, className}:any) {
  return (
    <svg
      width={width || "300"}
      height={height || "45"}
      viewBox="0 0 300 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.32249 22.1358H297.677V32.5516H293.566V45.0003H295.889V34.5064H300V20.1818H162.633C168.033 19.5808 174.441 18.4347 178.978 16.2411C192.672 9.63127 182.872 -3.33049 171.63 0.794153C167.943 2.14714 164.593 4.77348 161.755 7.69046C160.701 8.77662 159.716 9.90056 158.811 11.0163C156.468 13.9298 154.405 16.9961 152.643 20.1859H151.953C150.19 16.9959 148.128 13.9296 145.784 11.0163C144.88 9.90056 143.894 8.77662 142.84 7.69046C140.004 4.77759 136.652 2.15125 132.965 0.798258C121.722 -3.32639 111.923 9.63538 125.617 16.2484C130.157 18.4388 136.563 19.5849 141.962 20.1892H0V34.5064H4.11121V44.9904H6.4337V32.5516H2.32249V22.1358ZM184.259 8.45644C187.001 14.3035 168.523 18.4495 157.228 20.1793H153.024C161.111 13.1246 181.155 1.84009 184.259 8.45644ZM120.341 8.45644C123.446 1.83763 143.494 13.1221 151.576 20.1793H147.372C136.072 18.452 117.594 14.306 120.336 8.45644H120.341Z"
        fill="#F7871D"
      />
    </svg>
  );
}
