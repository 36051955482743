/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import IconModalGiftCode from "../../../../public/assets/icons/IconModalGiftCode";
import { TYPE_GIFT_OTHER } from "../utils/constant";
import CXModal from "../../common/components/CXModal";
import { giftAction } from "../redux/action";

function ModalGiftCode({
  giftCode,
  typeGift,
  openModalGiftCode,
  handleChangeGiftCode,
  closeModal
}: any) {
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(giftAction.handleCleanGiftItemExchange());
    };
  }, []);
  return (
    <CXModal
      open={openModalGiftCode}
      handleClose={closeModal}
      minHeight="100"
      isShowDivider={false}
      className="rounded-[16px]"
      isHeader={false}
    >
      <div className="my-[20px]">
        <div className="flex justify-center mt-[20px]">
          <span>
            <IconModalGiftCode />
          </span>
        </div>
        <div className="text-center mb-[10px]">
          <span className="text-[23px] text-[#006000] uppercase">
            {giftCode}
          </span>
        </div>
        <div className="text-center mb-[15px]">
          <span className="text-[15px] text-blackQuiz normal-case">
            {typeGift === TYPE_GIFT_OTHER ? (
              <FormattedMessage id="gift.modal.gift.code.title.contact" />
            ) : (
              <FormattedMessage id="gift.modal.gift.code.title.apply" />
            )}
          </span>
        </div>
        <div className="flex justify-center my-[5px] mt-[10px] mb-[12px]">
          <Button
            onClick={closeModal}
            className="flex justify-center items-center w-[149px] h-[44px] py-[14px] px-[34px] text-green-gradient text-[13px] font-[500] rounded-[100px] text-green-gradient normal-case"
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          {typeGift === TYPE_GIFT_OTHER ? (
            <a
              href={intl.formatMessage({
                id: "link.facebook"
              })}
              target="_blank"
              className="flex justify-center items-center w-[149px] h-[44px] py-[14px] px-[34px] text-white text-[13px] font-[500] rounded-[100px] bg-accept normal-case"
            >
              <FormattedMessage id="gift.button.title.contact" />
            </a>
          ) : (
            <Button
              className="flex justify-center items-center w-[149px] h-[44px] py-[14px] px-[34px] text-white text-[13px] font-[500] rounded-[100px] bg-accept normal-case"
              onClick={handleChangeGiftCode}
            >
              <FormattedMessage id="gift.button.title.apply" />
            </Button>
          )}
        </div>
      </div>
    </CXModal>
  );
}

export default ModalGiftCode;
